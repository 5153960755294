<template>
	<!-- nav顶部菜单项 -->
	<div class="menuNavTitle">
		<!-- <div v-show="!permission.admin"></div> -->
		<div class="change" v-show="permission.admin" @click="changeButton">
			<p>
				<i class="el-icon-refresh"></i>&nbsp;切换管理端
			</p>
		</div>
		<div>&nbsp;可帮考试系统&nbsp;</div>
		<div class="headerInfo">
			<div class="p1">
				<p>
					<i class="el-icon-user"></i>&nbsp;{{name}}
				</p>
			</div>
			<div class="p2" @click="editbtn">
				<p>
					<i class="el-icon-key"></i>&nbsp;修改密码
				</p>
			</div>
			<div class="p3" @click="exitbtn">
				<p>
					<i class="el-icon-switch-button"></i>&nbsp;退出系统
				</p>
			</div>
		</div>
	</div>
</template>

<script>
	import Cookies from 'js-cookie';
	export default {
		name: 'QuizSystem',
		props: {
		      navValue: Object
		},
		data() {
			return {
				name: '',
				permission:{}
			}
		},
		// created() {
		// 	var that = this;
		// 	clearInterval(that.time);
		// 	that.time = setInterval(function() {
		// 		that.getData();
		// 		if (Cookies.get("accessToken") && that.name) {
		// 			clearInterval(that.time);
		// 		}
		// 	}, 100);
		// },
		mounted() {
			var that=this;
			that.name=that.navValue.name;
			that.permission=that.navValue.permission;
		},
		methods:{
			getData() {
				var that = this;
				that.name = localStorage.getItem("userName");
				var arr = localStorage.getItem("permission");
				if (arr) {
					that.permission = JSON.parse(arr);
				}
			},
			// 切换管理端
			changeButton(){
				this.$router.push('/home');
			},
			// 修改密码
			editbtn() {
				if (navigator.onLine == true) {
					var _that = this;
					window.location.href =
						_that.COMMON.canChangeUrl +
						"?redirect_uri=" +
						_that.COMMON.quizUrl +
						"&client_id=" +
						_that.COMMON.clientId +
						"&power=" +
						_that.COMMON.power + "&verSion=" + _that.COMMON.version;
				} else {
					this.$alert("请检查当前网络!", "提示", {
						confirmButtonText: "确定",
						type: "error",
						callback: action => {}
					});
				}
			},
			// 退出登录
			exitbtn() {
				if (navigator.onLine == true) {
					let _that = this;
					_that.$axios
						.post("/oauth/logout")
						.then(function(res) {
							if (res.data == true) {
								Cookies.remove("accessToken", {
									path: "",
									domain: _that.COMMON.domain
								});
								Cookies.remove("refreshToken", {
									path: "",
									domain: _that.COMMON.domain
								});
								Cookies.remove("expires", {
									path: "",
									domain: _that.COMMON.domain
								});
								Cookies.remove("scope", {
									path: "",
									domain: _that.COMMON.domain
								});
								localStorage.removeItem("userName");
								localStorage.removeItem("permission");
								window.location.href =
									_that.COMMON.canhelpUrl +
									"?redirect_uri=" +
									_that.COMMON.quizUrl +
									"&client_id=" +
									_that.COMMON.clientId +
									"&power=" +
									_that.COMMON.power + "&verSion=" + _that.COMMON.version;
							}
						})
						.catch(function(error) {
							return false;
						});
				} else {
					this.$alert("请检查当前网络!", "提示", {
						confirmButtonText: "确定",
						type: "error",
						callback: action => {}
					});
				}
			},
			
		}
	}
</script>

<style lang='scss' scoped>
	
	/* 顶部导航 */
	.menuNavTitle {
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #e4e4e4;
		box-sizing: border-box;
		background: #FFFFFF;
		.el-radio-group>>>.el-radio-button__inner {
			border: none !important;
		}
		.change{
			height: 100%;
			background: #D7A24A;
			cursor: pointer;
			p{
				font-size: 14px;
				padding: 0px 10px;
				color: #FFFFFF;
			}
		}
		/* 顶部个人信息下拉框 */
		.headerInfo {
			color: "#fff";
			display: flex;
			height: 100% !important;
			color: #FFFFFF;
			font-size: 14px;
			cursor: pointer;
			.p1{
				background: #000000;
				padding: 0px 10px;
			}
			.p2{
				background: #D7A24A;
				padding: 0px 10px;
			}
			.p3{
				background: #D16947;
				padding: 0px 10px;
			}
		}
	}
</style>
